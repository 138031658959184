import React,{Component, Fragment} from "react"
import { Container, Row, Col,Tabs,Tab,Nav } from "react-bootstrap"
import BanFLag from '../../assets/images/coverage/bangladesh.png'
import malFLag from '../../assets/images/coverage/malaysia.jpg'
import thaiFLag from '../../assets/images/coverage/thailand.png'
import hongFLag from '../../assets/images/coverage/hongkong.png'
import IndonesiaFLag from '../../assets/images/coverage/indonesia.png'
import KuwaitFlag from '../../assets/images/coverage/kuwait.png'
import IraqFlag from '../../assets/images/coverage/iraq.png'
import IvoryCoastFlag from '../../assets/images/coverage/ivorycoast.png'
import SenegalFlag from '../../assets/images/coverage/senegal.jpg'
import Bangladesh from '../Coverage/Bangladesh'
import Malaysia from '../Coverage/Malaysia'
import Thailand from '../Coverage/Thailand'
import HongKong from '../Coverage/HongKong'
import Indonesia from '../Coverage/Indonesia'
import Kuwait from '../Coverage/Kuwait'
import Iraq from '../Coverage/Iraq'
import IvoryCoast from '../Coverage/IvoryCoast'
import Senegal from '../Coverage/Senegal'


class Coverage extends React.Component{
    render(){
        return(
            <Fragment>
                <section className="pt-100" id="country">
                    <Container>
                    <Row className="d-flex justify-content-center">
                            <Col sm={12} md={12} lg={7}>
                                <div className="section-head text-center">
                                    <h2>Our Coverage</h2>
                                    <p>Phosfluorescently develop world-class paradigms whereas accurate opportunities. 
                                    Enthusiastically architect accurate networks before competitive mindshare.</p>
                                    <span className="line"></span>
                                </div>
                            </Col>
                        </Row>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="bangladesh">
                            <Row>
                                <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="bangladesh">
                                            <span className="country-content"><img src={BanFLag} alt="Bangladeshi Flag" />Bangladesh</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="malaysia">
                                            <span className="country-content"><img src={malFLag} alt="Malaysia Flag" />Malaysia</span>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="thailand">
                                            <span className="country-content"><img src={thaiFLag} alt="Thailand Flag" />Thailand</span>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="hongkong">
                                            <span className="country-content"><img src={hongFLag} alt="Hong Kong Flag" />Hong Kong</span>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="indonesia">
                                            <span className="country-content"><img src={IndonesiaFLag} alt="Indonesia Flag" />Indonesia</span>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="iraq">
                                            <span className="country-content"><img src={IraqFlag} alt="Iraq Flag" />Iraq</span>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="kuwait">
                                            <span className="country-content"><img src={KuwaitFlag} alt="Kuwait Flag" />Kuwait</span>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="ivorycoast">
                                            <span className="country-content"><img src={IvoryCoastFlag} alt="Iraq Flag" />IvoryCoast</span>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="senegal">
                                            <span className="country-content"><img src={SenegalFlag} alt="Iraq Flag" />Senegal</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    
                                </Nav>
                                </Col>
                                <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="bangladesh">
                                        <Bangladesh/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="malaysia">
                                        <Malaysia/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="thailand">
                                        <Thailand/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="hongkong">
                                        <HongKong/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="indonesia">
                                        <Indonesia/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="kuwait">
                                        <Kuwait/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="iraq">
                                        <Iraq/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="ivorycoast">
                                        <IvoryCoast/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="senegal">
                                        <Senegal/>
                                    </Tab.Pane>
                                </Tab.Content>
                                </Col>
                            </Row>
                            </Tab.Container>
                    </Container>
                </section>
            </Fragment>
        )
    }
}

export default Coverage