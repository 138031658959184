import React,{Component, Fragment} from "react"
import { Container, Row, Col } from "react-bootstrap"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';


class Review extends React.Component{
    render(){
        var settings = {
            autoplaySpeed:3000,
            autoplay:true,
            dots: true,
            infinite: true,
            speed: 3000,
            vertical:true,
            verticalSwiping:true,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return(
            <section className="review pt-100 pb-70" id="review">
                <Container>
                    <Row className="d-flex justify-content-center">
                        <Col sm={12} md={12} lg={7}>
                            <div className="section-head text-center">
                                <h2>What Our Clients Says</h2>
                                <p>Phosfluorescently develop world-class paradigms whereas accurate opportunities. 
                                Enthusiastically architect accurate networks before competitive mindshare.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center" >
                        <Col lg={8} md={12} sm={12}>
                            <Slider {...settings}>
                                <div className="review-item text-center">
                                    <div className="icon"><FontAwesomeIcon icon={faQuoteRight} /></div>
                                    <em>Dynamically create multimedia based applications rather than granular applications. 
                                        Proactively revolutionize compelling convergence vis-a-vis competitive schemas. 
                                        Holisticly recaptiualize robust relationships whereas magnetic customer service. 
                                        Dynamically optimize high-payoff experiences with turnkey sources. 
                                        Completely utilize goal-oriented e-tailers rather than dynamic innovation.
                                    </em>
                                    <div className="clients-info">
                                        <h3>Moris Jacker</h3>
                                        <span>Web Developer</span>
                                    </div>
                                </div>
                                <div className="review-item text-center">
                                    <div className="icon"><FontAwesomeIcon icon={faQuoteRight} /></div>
                                    <em>Dynamically create multimedia based applications rather than granular applications. 
                                        Proactively revolutionize compelling convergence vis-a-vis competitive schemas. 
                                        Holisticly recaptiualize robust relationships whereas magnetic customer service. 
                                        Dynamically optimize high-payoff experiences with turnkey sources. 
                                        Completely utilize goal-oriented e-tailers rather than dynamic innovation.
                                    </em>
                                    <div className="clients-info">
                                        <h3>Alex Maxwel</h3>
                                        <span>Agent Management</span>
                                    </div>
                                </div>
                                <div className="review-item text-center">
                                    <div className="icon"><FontAwesomeIcon icon={faQuoteRight} /></div>
                                    <em>Dynamically create multimedia based applications rather than granular applications. 
                                        Proactively revolutionize compelling convergence vis-a-vis competitive schemas. 
                                        Holisticly recaptiualize robust relationships whereas magnetic customer service. 
                                        Dynamically optimize high-payoff experiences with turnkey sources. 
                                        Completely utilize goal-oriented e-tailers rather than dynamic innovation.
                                    </em>
                                    <div className="clients-info">
                                        <h3>Edmond Halley</h3>
                                        <span>Web Developer</span>
                                    </div>
                                </div>
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}
export default Review