import React,{Component, Fragment} from "react"
import { Container,Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import designImg from '../../assets/images/data/01.jpg'

class PlatformService extends React.Component{
    render(){
        return(
            <Fragment>
                <section className="design-section platform-service">
                    <Container fluid>
                        <Row className="align-items-center">
                            <Col lg={6} md={6} sm={12} className="service-thumb p-0">
                                <img src={designImg} alt="Design Image" />
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className="design-content">
                                    <div className="section-head left">
                                        <h2 className="mb-0">Platform <span>Service</span></h2>
                                        <span className="line mt-2 mb-2"></span>
                                        <p>We are providing platform services to local and global telecommunications.</p>
                                    </div>
                                    <ul className="design-list">
                                        <li>
                                            <span><FontAwesomeIcon icon={faCheckSquare} /></span>
                                            <strong>M-Analytics:</strong>
                                            <p className="pt-2">Customer segmenting platform to operators.</p>
                                        </li>
                                        <li>
                                            <span><FontAwesomeIcon icon={faCheckSquare} /></span>
                                            <strong>M-Rewards:</strong>
                                            <p className="pt-2">Audio advertising solution to segmented audience.</p>
                                        </li>
                                        <li>
                                            <span><FontAwesomeIcon icon={faCheckSquare} /></span>
                                            <strong>Call Lending:</strong>
                                            <p className="pt-2">Automatically credits zero balance subscribers with an amount of credit set by the operators.</p>
                                        </li>
                                        <li>
                                            <span><FontAwesomeIcon icon={faCheckSquare} /></span>
                                            <strong>Data Lending:</strong>
                                            <p className="pt-2">: Allows postpaid or prepaid subscribers with 0 MB data to automatically acquire new data bundles based on proprietary scoring algorithms used to analyze the users’ behavior.</p>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Fragment>
        )
    }
}

export default PlatformService