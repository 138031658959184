import React,{Component,Fragment} from 'react';
import { Container,Row, Col, Button } from 'react-bootstrap';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/style.css';
import bannerImage  from '../../assets/images/topBanner/img01.png';

class TopBanner extends React.Component{
    constructor(props){
        super();
        this.state={
            bannerImg:bannerImage,
        }
    }
    render(){
        return (
            <Fragment>
                <div className="heroSection">
                    <Container className="h100">
                        <Row className="d-flex align-items-center h100">
                            <Col sm={12} md={7} lg={6}>
                                <div className="banner-content">
                                    <h1>Digital Services,<br/>Software Development</h1>
                                    <p>Brain Pillow Limited is an IT company focused on web & mobile application
                                       development. We also provide adaptable Value Added Services (VAS) & 
                                       Digital Services to Telecommunication companies through partnership.
                                    </p>
                                    <div className="mt-30">
                                        <Button className="custom-btn">Get Started</Button>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} md={5} lg={5}>
                                <img src={this.state.bannerImg} alt="Banner Image" />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Fragment>
        )
    }
}

export default TopBanner;