import React,{Component, Fragment} from "react"
import { Container, Row, Col,Tabs,Tab } from "react-bootstrap"
import img1 from '../../assets/images/coverage/kuwait-01.jpg'

class Kuwait extends React.Component{
    render(){
        return(
            <Fragment>
                <Row className="justify-content-center">
                    <Col lg={12} md={4} sm={6} xs={12} className="d-flex align-item-center mb-15">
                        <img className="object-contain" src={img1} alt="Kuwait"/>
                    </Col>
                </Row>
            </Fragment>
            )
        }
}

export default Kuwait