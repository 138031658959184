import React,{Component, Fragment} from "react"
import { Container, Row, Col } from "react-bootstrap"
import img1 from "../../assets/images/portfolio/01.png"
import img2 from "../../assets/images/portfolio/02.png"
import img3 from "../../assets/images/portfolio/03.png"
import img4 from "../../assets/images/portfolio/04.png"
import img5 from "../../assets/images/portfolio/05.png"
import img6 from "../../assets/images/portfolio/06.png"
import img7 from "../../assets/images/portfolio/07.png"
import img8 from "../../assets/images/portfolio/08.png"
import img9 from "../../assets/images/portfolio/09.png"

class Portfolio extends React.Component{
    render(){
        return(
            <Fragment>
                <section className="portfolio pt-100 pb-70" id="portfolio">
                    <Container>
                        <Row className="justify-content-center">
                            <Col sm={12} md={12} lg={7}>
                                <div className="section-head text-center">
                                    <h2>Our <span>Portfolio</span> </h2>
                                    {/* <p>Phosfluorescently develop world-class paradigms whereas accurate opportunities. 
                                    Enthusiastically architect accurate networks before competitive mindshare.</p>
                                    <span className="line"></span> */}
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center justify-content-center">
                            <Col lg={4} md={6} sm={12}>
                                <div className="item">
                                    <div className="content">
                                        <a href="https://play.google.com/store/apps/details?id=com.sobkhobor.govjob" target="_blank">
                                            <img src={img1} alt="img1" />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <div className="item">
                                    <div className="content">
                                        <a href="https://play.google.com/store/apps/details?id=com.sobkhobor.haircut" target="_blank">
                                            <img src={img2} alt="img2" />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <div className="item">
                                    <div className="content">
                                        <a href="https://play.google.com/store/apps/details?id=com.sobkhobor.mensfashion" target="_blank">
                                            <img src={img3} alt="img3" />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col lg={4} md={6} sm={12}>
                                <div className="item">
                                    <div className="content">
                                        <a href="#">
                                            <img src={img4} alt="img4" />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <div className="item">
                                    <div className="content">
                                        <a href="#">
                                            <img src={img5} alt="img5" />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <div className="item">
                                    <div className="content">
                                        <a href="#">
                                            <img src={img6} alt="img6" />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <div className="item">
                                    <div className="content">
                                        <a href="#">
                                            <img src={img7} alt="img7" />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <div className="item">
                                    <div className="content">
                                        <a href="#">
                                            <img src={img8} alt="img8" />
                                        </a>
                                    </div>
                                </div>
                            </Col> */}
                        </Row>
                    </Container>
                </section>
            </Fragment>
        )
    }
}
export default Portfolio