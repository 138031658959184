import React,{Component, Fragment} from "react"
import { Container,Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import designImg from '../../assets/images/product/01.png'

class Product extends React.Component{
    render(){
        return(
            <Fragment>
                <section className="design-section pt-100 pb-70">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={6} md={6} sm={12}>
                            <div className="design-content">
                                    <div className="section-head left">
                                        <h2 className="mb-0">Products / Services<span></span></h2>
                                        <p>Entities in modern service “Chain”:</p>
                                        <span className="line mt-2 mb-2"></span>
                                        <p>At Brain Pillow Limited our entire team of professionals is enthusiastic in ensuring a deeply-rooted attachment with our clients by providing their desired solutions within the committed time frame. In a highly competitive business scenario, we ensure such maintenance of client relationship by:  </p>
                                    </div>
                                    <ul className="design-list v2">
                                        <li><span><FontAwesomeIcon icon={faCheckCircle} /></span>Content/Application Owner - who develops copyrighted contents.</li>
                                        <li><span><FontAwesomeIcon icon={faCheckCircle} /></span>Aggregator - collects content like games/wallpapers/ringtones etc. and distributes it to suit customer needs.</li>
                                        <li><span><FontAwesomeIcon icon={faCheckCircle} /></span>Software developer/ developer - develops applications (like payment/games/middleware etc.) or portal (basically the tech/digital end of the service) for mobile VAS/Digital solutions. </li>
                                        <li><span><FontAwesomeIcon icon={faCheckCircle} /></span>Technology Enabler - provides the platform that plugs into Telco networks and acts like a bridge between aggregator and Telco.  </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <img src={designImg} alt="Product" />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Fragment>
        )
    }
}

export default Product