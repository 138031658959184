import React,{Component, Fragment} from "react"
import { Container, Row, Col,Tabs,Tab } from "react-bootstrap"
import img1 from '../../assets/images/partner/hrplatform-01.png'

class HRPlatformPartner extends React.Component{
    render(){
        return(
            <Fragment>
                <Row className="align-item-center justify-content-center">
                    <Col lg={3} md={4} sm={6} xs={12} className="mb-15">
                        <div className="partner-item">
                            <img src={img1} alt="HR Partner"/>
                        </div>
                    </Col>
                </Row>
            </Fragment>
            )
        }
}

export default HRPlatformPartner