import React,{Component, Fragment} from 'react'
import { Container, Row,Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode,faHandHoldingUsd,faLayerGroup,faWallet,faMeteor,faHeadset } from '@fortawesome/free-solid-svg-icons';

class Services extends React.Component{
    render(){
        return(
            <Fragment>
                <Container fluid className="bg-gray pt-100 pb-70" id="services">
                    <Row className="d-flex justify-content-center">
                        <Col sm={12} md={12} lg={6}>
                            <div className="section-head text-center">
                                <h2>IT Enabled Services</h2>
                                <p>Phosfluorescently develop world-class paradigms whereas accurate opportunities. 
                                   Enthusiastically architect accurate networks before competitive mindshare.</p>
                                <span className="line"></span>
                            </div>
                        </Col>
                    </Row>
                    <Container>
                        <Row className="d-flex justify-content-center">
                            <Col sm={12} md={12} lg={4} className="mb-30">
                                <div className="serviceItem">
                                    <FontAwesomeIcon icon={faLaptopCode} />
                                    <h3>Games Marketplace</h3>
                                    <p>HTML and Android games for local and global VAS services</p>
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={4} className="mb-30">
                                <div className="serviceItem">
                                    <FontAwesomeIcon icon={faLayerGroup} />
                                    <h3>Content Platform</h3>
                                    <p>Video, Audio, Live Streaming platforms for Direct Carrier Billing or Payment Gateways </p>
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={4} className="mb-30">
                                <div className="serviceItem">
                                    <FontAwesomeIcon icon={faWallet} />
                                    <h3>Direct Carrier Billing</h3>
                                    <p>The pure telco payment method allows players to use the ‘pay by mobile’ payment option and directly charge the amount to their monthly bill.</p>
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={4} className="mb-30">
                                <div className="serviceItem">
                                    <FontAwesomeIcon icon={faHandHoldingUsd} />
                                    <h3>E-Wallet</h3>
                                    <p>Payment Gateway Integration with Nagad & Bkash Wallet.</p>
                                </div>
                            </Col>                            
                            {/* <Col sm={12} md={12} lg={4} className="mb-30">
                                <div className="serviceItem">
                                    <FontAwesomeIcon icon={faMeteor} />
                                    <h3>M-Rewards</h3>
                                    <p>Audio advertising solution to segmented audience. </p>
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={4} className="mb-30">
                                <div className="serviceItem">
                                    <FontAwesomeIcon icon={faHeadset} />
                                    <h3>Call Lending</h3>
                                    <p>Automatically credits zero balance subscribers with an amount of credit set by the operators. </p>
                                </div>
                            </Col> */}
                        </Row>
                    </Container>
                </Container>
            </Fragment>
        )
    }
}

export default Services;