import React from 'react';
import TopNavigation from '../src/components/TopNavigation/TopNavigation'
import TopBanner from '../src/components/TopBanner/TopBanner'
import Services from '../src/components/Services/Services'
import About from '../src/components/About/About'
import Future from '../src/components/Future/Future'
import Features from '../src/components/Features/Features'
import OtherServices from '../src/components/OtherServices/OtherServices'
import PlatformService from '../src/components/Platform/PlatformService'
import Policy from '../src/components/Policy/Policy'
import Product from '../src/components/Product/Product'
import Achivement from '../src/components/Achivement/Achivement'
import Team from '../src/components/Team/Team'
import Portfolio from '../src/components/Portfolio/Portfolio'
import Partner from '../src/components/Partner/Partner'
import Coverage from '../src/components/Coverage/Coverage'
import Review from '../src/components/Review/Review'
import Footer from '../src/components/Footer/Footer'


function App() {
  return (
    <div>
      <TopNavigation/>
      <TopBanner/>
      <About/>
      <Services/>
      {/* <Product/> */}
      <PlatformService/>
      {/* <OtherServices/> */}
      <Features/>
      {/* <Policy/> */}
      {/* <Future/> */}
      {/* <Achivement/> */}
      {/* <Team/> */}
      <Portfolio/>
      <Partner/>
      {/* <Review/> */}
      {/* <Coverage/> */}
      <Footer/>
    </div>
  );
}

export default App;
