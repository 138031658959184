import React,{Component, Fragment} from "react"
import { Container, Row, Col,Navbar,Nav,NavDropdown,NavLink } from "react-bootstrap"
import logo from '../../assets/images/logo.png'
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

class TopNavigation extends React.Component{
    constructor(props){
        super();
        this.state={
            navBarTitle:"navTitle",
            navBarLogo:[logo],
            navVariant:"dark",
            navBarBack:"navBackground",
            navBarItem:"navItem",
            title:props.title
        }
    }
    onScroll=()=>{
        if(window.scrollY>100){
            this.setState({navVariant:'light',navBarTitle:'navTitleScroll', navBarLogo:[logo], navBarBack:'navBackgroundScroll',navBarItem:'navItemScroll'})
        }
        else if(window.scrollY<100){
            this.setState({navVariant:'dark' ,navBarTitle:'navTitle',navBarLogo:[logo],navBarBack:'navBackground',navBarItem:'navItem'})
        }
    }
    componentWillMount() {
        window.addEventListener('scroll',this.onScroll)
    }
    render(){
        return(
            <Fragment>
                <section className="header" id="navigation">
                    <Navbar variant={this.state.navVariant} className={this.state.navBarBack} fixed="top" collapseOnSelect expand="lg">
                        <Container>
                            <Row className="w-100">                                
                                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                    <Navbar.Collapse id="responsive-navbar-nav">
                                    <Nav className="mr-auto">
                                    </Nav>
                                    <Nav>
                                        <Link className="nav-link" activeClass="active" to="navigation" spy={true} smooth={true} offset={0} duration={500} onSetActive={this.handleSetActive}>Home</Link>
                                        <Link className="nav-link" activeClass="active" to="about" spy={true} smooth={true} offset={-53} duration={500} onSetActive={this.handleSetActive}>About</Link>
                                        <Link className="nav-link" activeClass="active" to="services" spy={true} smooth={true} offset={-53} duration={500} onSetActive={this.handleSetActive}>Services</Link>
                                        {/* <Link className="nav-link" activeClass="active" to="pricing" spy={true} smooth={true} offset={-53} duration={500} onSetActive={this.handleSetActive}>Pricing</Link> */}
                                        <Link className="nav-link" activeClass="active" to="portfolio" spy={true} smooth={true} offset={-53} duration={500} onSetActive={this.handleSetActive}>Portfolio</Link>
                                        {/* <Link className="nav-link" activeClass="active" to="team" spy={true} smooth={true} offset={-53} duration={500} onSetActive={this.handleSetActive}>Team</Link> */}
                                        <Link className="nav-link" activeClass="active" to="partner" spy={true} smooth={true} offset={-53} duration={500} onSetActive={this.handleSetActive}>Partner</Link>
                                        {/* <Link className="nav-link" activeClass="active" to="review" spy={true} smooth={true} offset={-153} duration={500} onSetActive={this.handleSetActive}>Review</Link> */}
                                        <Link className="nav-link" activeClass="active" to="contact" spy={true} smooth={true} offset={-53} duration={500} onSetActive={this.handleSetActive}>Contact</Link>
                                    </Nav>
                                </Navbar.Collapse>    
                            </Row>    
                        </Container>           
                    </Navbar>
                </section>
            </Fragment>
        )
    }
}
export default TopNavigation