import React,{Component, Fragment} from "react"
import { Container, Row, Col,Media } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import Background from '../../assets/images/features/img-01.png'

class OtherServices extends React.Component{
    render(){
        return(
            <Fragment>
                <section className="design-section features">
                    <Container fluid>
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <div className="feature-wrap pt-5 pb-5">
                                    <div className="section-head left">
                                        <h2 className="mb-0">Our Other Services</h2>
                                        <span className="line mt-0 mb-1"></span>
                                        <p>We are currently delivering SMS, WAP, IVR, APP, USSD, RBT etc. 
                                            based quality services in/through the vast & ever booming Telecommunication service based market.</p>
                                    </div>
                                    <ul className="design-list">
                                        <li>
                                            <span><FontAwesomeIcon icon={faCheckSquare} /></span> 
                                            Customer segmenting platform to operators.
                                        </li>
                                        <li>
                                            <span><FontAwesomeIcon icon={faCheckSquare} /></span> 
                                            Mobile applications including: Competitions, Chat, Voting, Games, Web Portals, WAP Portals.
                                        </li>
                                        <li>
                                            <span><FontAwesomeIcon icon={faCheckSquare} /></span> 
                                            IVR platforms and content services.
                                        </li>
                                        <li>
                                            <span><FontAwesomeIcon icon={faCheckSquare} /></span> 
                                            Bulk SMS.
                                        </li>
                                        <li>
                                            <span><FontAwesomeIcon icon={faCheckSquare} /></span> 
                                            User Generated Content.
                                        </li>
                                        <li>
                                            <span><FontAwesomeIcon icon={faCheckSquare} /></span> 
                                            USSD Platforms and Services.
                                        </li>
                                        <li>
                                            <span><FontAwesomeIcon icon={faCheckSquare} /></span> 
                                            SDP (Service Delivery Platform).
                                        </li>
                                        <li>
                                            <span><FontAwesomeIcon icon={faCheckSquare} /></span> 
                                            SMS, MMS, and WAP Push Information and Entertainment channels.
                                        </li>
                                        <li>
                                            <span><FontAwesomeIcon icon={faCheckSquare} /></span> 
                                            Other deliverables are available upon necessity. 
                                        </li>
                                        <li>
                                            <span><FontAwesomeIcon icon={faCheckSquare} /></span> 
                                            Network VAS.
                                        </li>
                                        <li>
                                            <span><FontAwesomeIcon icon={faCheckSquare} /></span> 
                                            Enterprise VAS. 
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="p-0 feature-image" style={{ backgroundImage: "url(" + Background + ")" }}></Col>
                        </Row>
                    </Container>
                </section>
            </Fragment>
        )
    }
}

export default OtherServices