import React,{Component, Fragment} from "react"
import { Container, Row, Col,Form,Button} from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook,faLinkedin,faTwitter,faInstagram,faWhatsapp,faSkype } from '@fortawesome/free-brands-svg-icons';
import { faPhoneAlt,faEnvelope,faMapMarker,faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';

class Footer extends React.Component{
    render(){
        return(
            <Fragment>
                <section className="bg-gray pt-100 pb-60" id="contact">
                    <Container>
                        {/* <Row className="pb-60">
                            <Col lg={6} md={6} sm={12}>
                                <div className="subscribe-content">
                                    <h2>Join Our Newsletter</h2>
                                    <p>Progressively formulate effective customer service after intuitive technology. Distinctively negotiate timely technology rather than installed base. </p>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <Form>
                                    <Form.Row className="align-items-center">
                                        <Col sm={12} className="newsletter-form">
                                            <Form.Control id="inlineFormInputName" className="input-newsletter" placeholder="Enter your email" />
                                            <Button type="submit">Submit</Button>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col lg={4} md={4} sm={12}>
                                <div className="footer-widget">
                                    <h3>About Us</h3>
                                    <p>At Brain Pillow Limited our entire team of professionals is enthusiastic to engage with our clients and to provide their desired solutions within the committed time frame.</p>
                                    <ul className="social-list">
                                        {/* <li><a href="https://www.linkedin.com/company/brain-pillow/" target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                                        <li><a href="#"><FontAwesomeIcon icon={faFacebook} /></a></li>
                                        <li><a href="#"><FontAwesomeIcon icon={faTwitter} /></a></li>
                                        <li><a href="#"><FontAwesomeIcon icon={faInstagram} /></a></li> */}
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={3} md={4} sm={12}>
                                <div className="footer-widget">
                                    <h3>Contact Us</h3>
                                    <div className="footer-info-contact">
                                        <FontAwesomeIcon icon={faWhatsapp} />
                                        <h3>Phone</h3>
                                        <span>+8801911038258</span>
                                    </div>
                                    {/* <div className="footer-info-contact">
                                        <FontAwesomeIcon icon={faSkype} />
                                        <h3>Skype</h3>
                                        <span>toufique268</span>
                                    </div> */}
                                    <div className="footer-info-contact">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                        <h3>Email</h3>
                                        <span>support@brain-pillow.com</span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={5} md={5} sm={12}>
                                <div className="footer-widget">
                                    <h3>Office Address</h3>
                                    <div className="footer-info-contact">
                                        <FontAwesomeIcon icon={faMapMarker} />
                                        <strong>Brain Pillow Limited</strong>
                                        {/* <h3>Brain Pillow Limited</h3> */}
                                        <span>8 Gulfesha Plaza, Level 3, Suite J/K, Moghbazar, Dhaka-1217, Bangladesh</span>
                                    </div>                                    
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="footer-bottom p-4 text-center">
                    <p className="copy-right">Copyright @ 2022 All Rights Reserved by <a target="_blank" href="#">BrainPillow</a></p>
                </section>
            </Fragment>
        )
    }
}

export default Footer
