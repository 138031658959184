import React,{Component, Fragment} from "react"
import { Container, Row, Col,Media } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBezierCurve,faChartBar,faSwatchbook,faBullhorn,faVideo,faHeadset,faImages } from '@fortawesome/free-solid-svg-icons';
import Background from '../../assets/images/features/img-01.png'

class Features extends React.Component{
    render(){
        return(
            <Fragment>
                <section className="features">
                    <Container fluid>
                        <Row>
                        <Col lg={6} md={6} sm={12} className="p-0 feature-image" style={{ backgroundImage: "url(" + Background + ")" }}></Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className="design-section feature-wrap pt-5 pb-5 ml-0 pl-5">
                                    <div className="section-head left">
                                        <h2 className="mb-0">Types of digital content</h2>
                                        <span className="line mt-0 mb-1"></span>
                                        <p>Brain Pillow Limited desires to revolutionize the world of conveying entertainment, education, emergency services, medical services, 
                                            and lifestyle impacted through Brain Pillow's digital database content store and wishes to uphold the following banks of daily life entertainment</p>
                                    </div>
                                    <Media className="feature-item">
                                            <span className="align-self-center bg-pink mr-3"><FontAwesomeIcon icon={faImages} /></span>
                                        <Media.Body>
                                            <h5>Images</h5>
                                            <p>Photo and image involvement is another example of digital content. Popular sites used for this type of digital content include. </p>
                                        </Media.Body>
                                    </Media>
                                    <Media className="feature-item">
                                            <span className="align-self-center bg-purple mr-3"><FontAwesomeIcon icon={faVideo} /></span>
                                        <Media.Body>
                                            <h5>Video </h5>
                                            <p>Types of video content include home videos, music videos, TV shows, and movies, racing, travelers etc. </p>
                                        </Media.Body>
                                    </Media>
                                    <Media className="feature-item">
                                            <span className="align-self-center bg-light-green mr-3"><FontAwesomeIcon icon={faBezierCurve} /></span>
                                        <Media.Body>
                                            <h5>Software </h5>
                                            <p>Types of software content includes versatile range of Android/iOS/Symbian/Windows mobile based applications, games & other software development as per necessity.</p>
                                        </Media.Body>
                                    </Media>
                                    <Media className="feature-item">
                                            <span className="align-self-center bg-orange mr-3"><FontAwesomeIcon icon={faHeadset} /></span>
                                        <Media.Body>
                                            <h5> Audio </h5>
                                            <p>Music is the most common form of audio. There are several platforms which has emerged as a popular way for people to listen to music either over the Internet or from their computer desktop. 
                                                Digital content in the form of music is also available from our end which allows listeners to listen to music online/ downloadable.  </p>
                                        </Media.Body>
                                    </Media>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Fragment>
        )
    }
}

export default Features