import React,{Component, Fragment} from "react"
import { Container,Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import designImg from '../../assets/images/privacy/01.png'

class Future extends React.Component{
    render(){
        return(
            <Fragment>
                <section className="design-section bg-gray pt-100 pb-70">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={6} md={6} sm={12}>
                                <div className="design-content">
                                    <div className="section-head left">
                                        <h2 className="mb-0">Our Future market <span>Target</span></h2>
                                        <span className="line mt-2 mb-2"></span>
                                        <p>We believe in enhancing constantly and assist telecommunication providers worldwide in shaping their services exquisitely. </p>
                                        <p>Entertainment is all about grabbing the attention and interest of consumers and giving delight through an activity/task. Thus, presenting us the scope of providing various sort of services to the audience; which will please them and add value in their regular activities. Some of the future scopes may include VAS to be integrated in/amongst the following sectors: IPTV, Machine to Machine networked communication, Location Based Services, Diverse Intelligent Applications, Advanced Digital Advertising, Rich Communication Services messaging, Surveillance, Central Controlling of Household Equipment’s, online streamed Games, Streamed Audiovisual Contents etc. We desire to take new challenges with the developing world and provide our clients with a trendy and sassy service. </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <img src={designImg} alt="Policy" />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Fragment>
        )
    }
}

export default Future