import React,{Component, Fragment} from "react"
import { Container,Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import designImg from '../../assets/images/privacy/01.png'

class Policy extends React.Component{
    render(){
        return(
            <Fragment>
                <section className="design-section pt-100 pb-70">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={6} md={6} sm={12}>
                                <img src={designImg} alt="Policy" />
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className="design-content">
                                    <div className="section-head left">
                                        <h2 className="mb-0">Our Quality <span>Policy</span></h2>
                                        <span className="line mt-2 mb-2"></span>
                                        <p>At Brain Pillow Limited our entire team of professionals is enthusiastic in ensuring a deeply-rooted attachment with our clients by providing their desired solutions within the committed time frame. In a highly competitive business scenario, we ensure such maintenance of client relationship by:  </p>
                                    </div>
                                    <ul className="design-list v2">
                                        <li><span><FontAwesomeIcon icon={faCheckCircle} /></span>Providing a comfortable work environment. </li>
                                        <li><span><FontAwesomeIcon icon={faCheckCircle} /></span>Ensuring that quality standards are met prior to delivery of all services by continuous testing process.</li>
                                        <li><span><FontAwesomeIcon icon={faCheckCircle} /></span>Encouraging proactive employees, positive management and high performing teams.</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Fragment>
        )
    }
}

export default Policy