import React,{Component, Fragment} from "react"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook,faLinkedin,faTwitter,faInstagram } from '@fortawesome/free-brands-svg-icons';
import  img1  from '../../assets/images/teams/team1.jpg'
import  img2  from '../../assets/images/teams/team2.jpg'
import  img3  from '../../assets/images/teams/team3.jpg'

class Team extends React.Component{
    render(){
        return(
            <Fragment>
                <Container className="pt-100 pb-70" id="team">
                    <Row className="d-flex justify-content-center">
                        <Col sm={12} md={12} lg={7}>
                            <div className="section-head text-center">
                                <h2>Our Expert Team</h2>
                                <p>Phosfluorescently develop world-class paradigms whereas accurate opportunities. 
                                   Enthusiastically architect accurate networks before competitive mindshare.</p>
                                <span className="line"></span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <div className="expert-team text-center">
                                <div className="thumb">
                                    <img src={img1} alt="img01" />
                                    <ul className="social-list">
                                        <li><a href="#"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                                        {/* <li><a href="#"><FontAwesomeIcon icon={faFacebook} /></a></li>
                                        <li><a href="#"><FontAwesomeIcon icon={faTwitter} /></a></li>
                                        <li><a href="#"><FontAwesomeIcon icon={faInstagram} /></a></li> */}
                                    </ul>
                                </div>
                                <div className="content">
                                    <h3>Mahmud Nahid</h3>
                                    <p>Chief Technical Officer</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="expert-team text-center">
                                <div className="thumb">
                                    <img src={img2} alt="img02" />
                                    <ul className="social-list">
                                        <li><a href="https://www.linkedin.com/in/toufiquemahabub/" target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                                        {/* <li><a href="#"><FontAwesomeIcon icon={faFacebook} /></a></li>
                                        <li><a href="#"><FontAwesomeIcon icon={faTwitter} /></a></li>
                                        <li><a href="#"><FontAwesomeIcon icon={faInstagram} /></a></li> */}
                                    </ul>
                                </div>
                                <div className="content">
                                    <h3>Toufique Mahabub</h3>
                                    <p>Business Development</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="expert-team text-center">
                                <div className="thumb">
                                    <img src={img3} alt="img03" />
                                    <ul className="social-list">
                                        <li><a href="#"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                                        {/* <li><a href="#"><FontAwesomeIcon icon={faFacebook} /></a></li>
                                        <li><a href="#"><FontAwesomeIcon icon={faTwitter} /></a></li>
                                        <li><a href="#"><FontAwesomeIcon icon={faInstagram} /></a></li> */}
                                    </ul>
                                </div>
                                <div className="content">
                                    <h3>SM Shopnil</h3>
                                    <p>Devops Engineer</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default Team;