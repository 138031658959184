import React,{Component, Fragment} from "react"
import { Container, Row, Col,Tabs,Tab } from "react-bootstrap"
import ContentPartner from '../Partner/ContentPartner'
import PlatformPartner from '../Partner/PlatformPartner'
import EWallet from '../Partner/EWallet'
import HRPlatformPartner from '../Partner/HRPlatformPartner'
import TransportPartner from '../Partner/TransportPartner'
import ServicePartner from '../Partner/ServicePartner'


class Partner extends React.Component{
    render(){
        return(
            <Fragment>
                <section className="bg-gray pt-100 pb-70" id="partner">
                    <Container>
                        <Row className="d-flex justify-content-center">
                            <Col sm={12} md={12} lg={7}>
                                <div className="section-head text-center">
                                    <h2>Our Partners</h2>
                                </div>
                            </Col>
                        </Row>
                            <section className="partner-tab">
                                <Tabs defaultActiveKey="content" id="noanim-tab-example">
                                    <Tab eventKey="content" title="Content Partners">
                                        <ContentPartner/>
                                    </Tab>
                                    <Tab eventKey="platform" title="Platform Partner">
                                        <PlatformPartner/>
                                    </Tab>                                    
                                    <Tab eventKey="ewallet" title="E-wallet Partner">
                                        <EWallet/>
                                    </Tab>                                    
                                </Tabs>
                            </section>

                    </Container>
                </section>
            </Fragment>
        )
    }
}

export default Partner