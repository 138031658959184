import React,{Component, Fragment} from "react"
import { Container, Row, Col,Tabs,Tab } from "react-bootstrap"
import img1 from '../../assets/images/coverage/hong-01.png'
import img2 from '../../assets/images/coverage/hong-02.png'

class HongKong extends React.Component{
    render(){
        return(
            <Fragment>
                <Row className="justify-content-center">
                    <Col lg={3} md={4} sm={6} xs={12} className="d-flex align-item-center mb-15">
                        <img className="object-contain" src={img1} alt="HongKong"/>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12} className="d-flex align-item-center mb-15">
                        <img className="object-contain" src={img2} alt="HongKong"/>
                    </Col>
                </Row>
            </Fragment>
            )
        }
}

export default HongKong