import React,{Component, Fragment} from 'react';
import { Container, Row,Col,Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import aboutImg from '../../assets/images/about/01.png'
import designImg from '../../assets/images/product/01.png'



class About extends React.Component{
    render(){
        return(
            <Fragment>
            <section className="design-section pt-100 pb-70">
                <Container id="about">
                    <Row className="align-items-center">
                        {/* <Col lg={6} md={6} sm={12}>
                            <img src={aboutImg} alt="about image" />
                        </Col> */}
                        <Col lg={6} md={6} sm={12}>
                                <img src={designImg} alt="Product" />
                            </Col>
                        <Col lg={6} md={6} sm={12}>
                        <div className="design-content">
                                <div className="section-head left">
                                    <h2 className="mb-0">About Us<span></span></h2>
                                    <p>Software Development / Aggregator/ Technology Enabler</p>
                                    <span className="line mt-2 mb-2"></span>
                                    <p>At Brain Pillow Limited our entire team of professionals is enthusiastic to engage with our clients and to provide their desired solutions within the committed time frame. </p>
                                </div>
                                <ul className="design-list v2">
                                    <li><span><FontAwesomeIcon icon={faCheckCircle} /></span>Software Development - develops web & mobile application, e-commerce platform & portal for mobile VAS/Digital solutions. </li>
                                    <li><span><FontAwesomeIcon icon={faCheckCircle} /></span>Aggregator - collects content like games/videos/music/news etc. and distributes it to suit customer needs.</li>
                                    <li><span><FontAwesomeIcon icon={faCheckCircle} /></span>Technology Enabler - provides the platform (payment api/games marketplace/middleware etc.)that plugs into Telco networks and acts like a bridge between aggregator and Telco.  </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
        )
    }
}

export default About