import React,{Component, Fragment} from "react"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare,faSmile,faTools,faTrophy } from '@fortawesome/free-solid-svg-icons';

class Achivement extends React.Component{
    render(){
        return(
            <Fragment>
               <section className="achivement pt-100 pb-70 bg-gray">
                    <Container>
                        <div className="section-head text-center p-0">
                            <h2 className="mb-0">We Have Completed <span>200+</span> Projects  <span>Successfully</span></h2>
                        </div>
                        <Row>
                            <Col lg={3} md={4} sm={6} xs={12}>
                                <div className="item text-center bg-white p-4">
                                    <span><FontAwesomeIcon icon={faCheckSquare} /></span>
                                    <h4>950</h4>
                                    <p>Completed Project</p>
                                </div>
                            </Col>
                            <Col lg={3} md={4} sm={6} xs={12}>
                                <div className="item text-center bg-white p-4">
                                    <span><FontAwesomeIcon icon={faSmile} /></span>
                                    <h4>150</h4>
                                    <p>Happy Clients</p>
                                </div>
                            </Col>
                            <Col lg={3} md={4} sm={6} xs={12}>
                                <div className="item text-center bg-white p-4">
                                    <span><FontAwesomeIcon icon={faTools} /></span>
                                    <h4>10</h4>
                                    <p>Services</p>
                                </div>
                            </Col>
                            <Col lg={3} md={4} sm={6} xs={12}>
                                <div className="item text-center bg-white p-4">
                                    <span><FontAwesomeIcon icon={faTrophy} /></span>
                                    <h4>100</h4>
                                    <p>Winning Awards</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>  
                </section>   
            </Fragment>
        )
    }
}

export default Achivement;